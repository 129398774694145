@tailwind base;
@tailwind components;
@tailwind utilities;

*, *::before, *::after{
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html, body{
    background-color: #0a0815;
}